#background-profile {
    background: rgb(245,245,245);
    height: 100%;
    min-height: 100dvh;
}
.usersettings-l-r {
    margin-top: 3rem;
    margin-left: 10.5rem;
    margin-right: 9.5rem;
}
.cards_Width {
    max-width:55vw;
    border:none !important;
}
.user-settings-heading {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 500;
}

.card-user-padding {
    padding: 1rem;
}

.bg-user-text {
    display: flex;
}

.bg-user-text:hover {
    background-color: #FF8B66;
    border-radius: 3px;
}

.active-user-text {
    background-color: #FF8B66;
    border-radius: 3px;
}

.user-settings-padding {
    padding: 15px;
}

.card-usersettings {
    width: auto;
}

.account-settings {
    font-size: 20px;
    font-family: "Poppins";
}

.account-preferrences {
    color: #ADBEC4;
    font-size: 13px;
    font-family: "Poppins";
}

.change-username {
    color: #333333;
    font-size: 16px;
    font-family: "Poppins";
}

.change-btn {
    width: 6rem;
    height: 30px;
    background: #FFFFFF;
    border: 2px solid #E6570E;
    border-radius: 50px;
    font-family: poppins;
    font-size: 13px;
    color: #E6570E;
}

.change-color {
    color: #E6570E;
    text-decoration: none;
}

.change-color:hover {
    color: #E6570E;
    text-decoration: none;
}

.change-btn:hover {
    color: #E6570E;
    border: 2px solid #E6570E;
}

.english-btn {
    width: 12rem;
    height: 35px;
    background: #FFFFFF;
    border: 2px solid #E6570E;
    border-radius: 50px;
    font-family: poppins;
    font-size: 13px;
    color: #E6570E;
    float: right;
    margin-right: -1.8rem;
    margin-top: 1.4rem;
}

.english-btn:hover {
    color: #E6570E;
    border: 2px solid #E6570E;
}

.deactive-btn {
    width: 13rem;
    height: 35px;
    background: #FFFFFF;
    border: 2px solid #E6570E;
    border-radius: 50px;
    font-family: poppins;
    font-size: 13px;
    color: #E6570E;
    float: right;
    margin-right: -1.8rem;
    margin-top: 0.4rem;
}

.deactive-btn:hover {
    color: #E6570E;
    border: 2px solid #E6570E;
}

.right {
    margin-right: 4rem;
    margin-left: 4rem;
}

.username {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #727272;
    margin-top: -7px;
}

.username_ {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #727272;
    margin-top: -3px;
}

input[type="checkbox"].switch1 {
    position: absolute;
    opacity: 0;
}

input[type="checkbox"].switch1:checked+div {
    background-color: #FF8B66;
}

input[type="checkbox"].switch1+div {
    vertical-align: middle;
    width: 40px;
    height: 20px;
    border-radius: 999px;
    background-color: lightgrey;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transition-property: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    cursor: pointer;
}

input[type="checkbox"].switch1+div span {
    position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch1+div span:nth-child(1) {
    margin-left: 15px;
}

input[type="checkbox"].switch1+div span:nth-child(2) {
    margin-left: 55px;
}

input[type="checkbox"].switch1:checked+div {
    width: 40px;
    background-position: 0 0;
    background-color: lightgrey;
}

input[type="checkbox"].switch1+div {
    width: 40px;
    height: 20px;
}


input[type="checkbox"].switch1:checked+div {
    background-color: #FF8B66;
}

input[type="checkbox"].switch1+div>div {
    float: left;
    width: 18px;
    height: 18px;
    border-radius: inherit;
    background: #ffffff;
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color;
    transition-property: transform, background-color;
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
}

input[type="checkbox"].switch1:checked+div>div {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    background-color: #ffffff;
}



input[type="checkbox"].switch1:checked+div>div {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
}

.float {
    font-weight: 500;
    float: right;
    cursor: pointer;
    font-family: "Poppins";
}

.label-text {
    font-weight: 500;
    font-family: "Poppins";
}

.sort-text {
    color: #E6570E;
    font-family: "Poppins";
    font-size: 14px;
}

.sort-text-width {
    margin-right: -1rem;
    float: right;
    width: 12rem;
    color: #E6570E;
    font-family: "Poppins";
    font-size: 14px;
}

/* .profile-top-text-col {
    color: white;
    font-family: "Poppins";
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 5px 7px 5px;
    background-color: #E6570E;
} */
.profile-top-text-col{
    width:9rem;
    color: white;
    background-color: #E6570E;
    font-family: "Poppins";
    border-radius: 35px;
    font-size: 14px;
    cursor: pointer;
    padding: 6px 10px 5px 10px;
}

.profile-top-text-col-g {
    color: grey;
    font-family: "Poppins";
    font-size: 14px;
    cursor: pointer;
    padding: 5px 5px 7px 5px;
    width: 9rem;
    border-radius: 35px;
    border:1px solid grey;
}

.profile-top-next-button-g {
    width: auto;
    color: #3A3A3A;
    background: #F5F5F5;
    font-family: "Poppins";
    border-radius: 35px;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
}
.profile-top-text-col-n {
    color: grey;
    font-family: "Poppins";
    font-size: 14px;
    cursor: pointer;
    padding: 6px 6px 7px 6px;
    width: auto;
    border-radius: 35px;
}
.userprofile-l-r {
    margin-top: 0.3rem;
    margin-left: 11%;
    margin-right: 10%;
}
.bg-p-img{
    width:100%;
    height:150%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.profile-feedback-text-col-g {
    font-size: 12.5px;
    font-family: "poppins";
    color: grey;
}

.profile-feedback-text-col-b {
    color: black;
    font-size: 28px;
    font-family: "poppins";
}

.done-btn-sm {
    width: 44px;
    height: 18px;
    color: white;
    font-size: 10px;
    line-height: 1.2;
    padding: 1px 3px;
    background: #E6570E;
    margin-top: -0.2rem;
    margin-right: 4rem;
}
.done-btn-sm:hover{
    color: #fff;
}

.comment-profile {
    font-size: 12px;
    font-family: poppins;
    color: #AAAAAA;
}

.modal-box {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content-log {
    background-color: white;
    border: 0.2px solid #D3D3D3;
    border-radius: 18px;
    margin: auto;
    font-size: 16px;
    font-weight: 600;
    width: 20%;
}

.md-container {
    padding: 15px;
    text-align: center;
    z-index: 100;
}

.cancelbutton,
.deletebutton {
    width: 6rem;
    height: 33px;
    color: #E6570E;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #E6570E;
    padding-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
}

.cancelbutton:hover,
.deletebutton:hover {
    width: 6rem;
    height: 33px;
    font-weight: 600;
    font-size: 14px;
    color: white;
    border: 1px solid #E6570E;
    background-color: #E6570E;
    padding-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
}

.cancelbutton {
    cursor: pointer;
}

.deletebutton {
    cursor: pointer;
}
.feddback-profile{
    overflow-y: auto;
    height:76.5vh;
    overflow-x: hidden;
    scrollbar-width: none;
    padding:0 20px;
    padding-bottom: 100px;
}
.feddback-profile::-webkit-scrollbar { /* WebKit */
    display: none;
    -ms-overflow-style: none; 
}
.feddback-profile-ratings{
    overflow-y: auto;
    height:84vh;
    overflow-x: hidden;
    scrollbar-width: none;
}
.feddback-profile-ratings::-webkit-scrollbar { /* WebKit */
    display: none;
    -ms-overflow-style: none; 
}
.verifyOtpText {
    font-size: 13px;
    font-family: "Poppins";
    color: grey;
}

.font-family {
    font-family: "poppins";
}

.add-subadmin-text {
    color: #FF8B66;
    font-size: 16px;
    font-family: "poppins";
    cursor: pointer;
}

.save-pop-button {
    background-color: #E6570E;
    border-radius: 5px;
    color: white;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
    align-items: end;
}

.save-pop-button:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.cancel-pop-button {
    background-color: white;
    border-radius: 5px;
    color: #E6570E;
    border-color: white;
    font-family: 'Poppins';
    font-size: 15px;
    align-items: end;
}

.cancel-pop-button:hover {
    background-color: white;
    color: #E6570E;
    border-color: white;
}

.wrapper-sub {
    display: grid;
    grid-template-columns: 9% 25% 35% 17% 7%;
    grid-gap: 10px;
}

.wrapper-sub-padding {
    align-items: left;
    justify-content: left;
    font-family: 'Poppins';
    font-size: 15px;
}

.wrapper-back {
    width: 6rem;
    background-color: #E6570E;
    border-color: #E6570E;
    height: 32px;
    font-size: 14px;
    font-family: Poppins;
    color: white;
    border-radius: 20px;
    text-align: center;
    justify-content: center;
    padding-top: 5px;
}

.wrapper-name {
    font-size: 15px;
    font-family: Poppins;
    font-weight: 600;
}
.flex-container {
    display: flex;
  }
  
  .flex-container > div {
    margin: 10px;
  }
  .profile-username{
    align-items: left;
    justify-content: left;
    font-family: 'Poppins';
    font-size: 15px; 
    font-weight:600;
    margin-left: 3.5%;
    margin-top: -1.3rem;
  }
  .profile-Img{
    background-color: #f5f5f5;
    padding:11px 5px 20px 10px;
    height:80px;
    width:80px;
    margin-left: 3%;
    border-radius: 4px;
  }