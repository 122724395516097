.footer_bg {
    width: 100%;
    height: 100%;
    background-color: #E6570E
}

.footer-logo {
    width: 18%;
    margin-top: 3rem;
    margin-left: 3rem;
}

.footer-text {
    font-size: 14px;
    font-family: Poppins;
    color: white;
    margin-left: 3rem;
}

.links {
    margin-top: 3rem;
    color: white;
    font-size: 15px;
    font-weight: 700;
    margin-left: 0.8rem;
    font-family: Poppins;
}

.links-sm {
    color: white !important;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0.8rem;
    font-family: Poppins;
    text-decoration: none !important;
}

.newsletter {
    margin-top: 3rem;
    color: white;
    font-family: poppins;
    font-size: 14px;
    margin-right: 1rem;
    margin-left: 0.8rem;
}

.nav-links-footerbtn {
    background-color: #3A3A3A;
    width: 6rem;
    height: 30px;
    border-color: 10px solid #3A3A3A;
    border-radius: 30px;
    text-align: center;
    margin: auto;
    margin-right: 0rem;
    color: white;
    padding-top: 4px;
    font-size: 13px;
    float: right;
}

.nav-links-footerbtn:hover {
    background-color: #3A3A3A;
    color: white;
}

.footer-down-text {
    color: white;
    text-decoration: underline;
    font-size: 14px;
    font-family: 'Poppins';
}

.color {
    color: white;
    text-decoration: underline;
    text-decoration-color: white !important;
}

@media screen and (max-width:639px) {
    .footer_bg {
        width: 100%;
        height: 50%;
        background-color: #E6570E
    }

    .footer-logo {
        width: 20%;
        margin-top: 1rem;
        margin-left: 1rem;
    }

    .footer-text {
        font-size: 14px;
        font-family: Poppins;
        color: white;
        margin-left: 0.8rem;
    }

    .links {
        margin-top: 1.5rem;
        color: white;
        font-size: 15px;
        font-weight: 700;
        margin-left: 0.8rem;
        font-family: Poppins;
    }

    .links-sm {
        color: white !important;
        font-size: 14px;
        font-weight: 400;
        margin-left: 0.8rem;
        font-family: Poppins;
        text-decoration: none !important;
    }

    .newsletter {
        margin-top: 1rem;
        color: white;
        font-family: poppins;
        font-size: 14px;
    }

    .nav-links-footerbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        width: 6rem;
        height: auto;
        background: #3A3A3A;
        text-decoration: none;
        color: white;
        border-color: #3A3A3A;
        padding-top: 5px;
        padding-bottom: 8px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .footer-down-text {
        color: white;
        text-decoration: underline;
        font-size: 14px;
        font-family: 'Poppins';
    }
}