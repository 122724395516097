.l-heading {
    font-size: 30px;
    font-family: Poppins;
    color: black;
}

.fd-top-ab {
    position: absolute;
    top: 30%;
}

.enable-center {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
    margin: auto;
    width: 100%;
    border: none;
    min-height: 100vh;
    margin-top: 1.5%;
}

.lpbg-img {
    margin-top: 1.5rem;
    width: 25%;
    z-index: -1;
}

.lpbg-img_ {
    margin-left: 4rem;
    margin-top: 1.5rem;
    width: 25%;
    z-index: -1;
}

.lpbg-img-left {
    margin-left: 4rem;
    margin-top: 1.5rem;
    width: 25%;
    transform: rotate(180deg);
    z-index: -1;
}

.lpbg-img-left_ {
    margin-left: 6rem;
    width: 25%;
    transform: rotate(180deg);
    z-index: -1;
}

.rightsemicircle {
    float: right;
    position: absolute;
    z-index: -1;
    top: 45%;
    right: 0;
    width: 14%;
}

.leftsemicircle {
    float: left;
    position: absolute;
    z-index: -1;
    top: 128%;
    left: 0;
    width: 14%;
}

.enable-center-img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    border: none;
    min-height: 100vh;
}

.l-text {
    font-size: 15px;
    font-family: "Poppins";
    color: black;
}

.l-growingup {
    font-size: 30px;
    font-family: Poppins;
    color: black;
    font-weight: 600;
}

.pop-img {
    width: 65%;
}

.feddupwork-textheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #AAAAAA;
}

.feddupwork-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    color: #000000;
}

.feddupwork-text-left {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    color: #000000;
    margin-left: 4rem;
}
.grow-card{
    width:70%;
}
.l-lastupdated {
    font-size: 13px;
    font-family: "Poppins";
    color: #AAAAAA;
    margin-top: 10px;
}

.l-card {
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 15px #999;
}

.l-card-img {
    width: 2rem;
}

.l-card-count {
    font-size: 30px;
    font-family: "Poppins";
    color: #E6570E;
    font-weight: 600;
}

.l-card-text {
    font-size: 14px;
    font-family: "Poppins";
    color: #3A3A3A;
}

.contentpolicy {
    color: #E6570E;
    font-weight: 600;
    font-size: 20px;
    font-family: "Poppins";
}

.contentpolicy-text {
    margin-left: 20%;
    margin-right: 20%;
    font-size: 14px;
    font-style: normal;
    font-family: "Poppins";
}

.curvedline {
    width: 500px;
    border: solid 5px #aaaaaa;
    border-color: #aaaaaa transparent transparent transparent;
    border-radius: 50%/100px 100px 0 0;
}

#lower-line {
    border: solid 5px grey;
    width: 90%;
    height: 600px;
    border-radius: 50%;
    top: 220%;
    left: 0px;
    transform: rotate(-30deg);
    border-left: none;
    border-top: none;
    border-bottom: none;
    position: absolute
}

#upper-line {
    border: solid 5px grey;
    width: 90%;
    height: 600px;
    border-radius: 50%;
    left: 85px;
    transform: rotate(30deg);
    border-right: none;
    border-top: none;
    border-bottom: none;
    position: absolute;
    top: 300%;
}

#lower-line1 {
    border: solid 5px grey;
    width: 90%;
    height: 600px;
    border-radius: 50%;
    top: 380%;
    left: 0px;
    transform: rotate(-30deg);
    border-left: none;
    border-top: none;
    border-bottom: none;
    position: absolute;
}

/* p #lp-bg-img {
    background-image: url("../images/background-img.png");
    width: 50%;
} */

#upper-line1 {
    border: solid 5px grey;
    width: 90%;
    height: 600px;
    border-radius: 50%;
    left: 85px;
    transform: rotate(30deg);
    border-right: none;
    border-top: none;
    border-bottom: none;
    position: absolute;
    top: 460%;
}

#lower-line2 {
    border: solid 5px grey;
    width: 92%;
    height: 600px;
    border-radius: 50%;
    top: 530%;
    left: 0px;
    z-index: -1;
    transform: rotate(-40deg);
    border-left: none;
    border-top: none;
    border-bottom: none;
    position: absolute
}

#content-1 {
    position: absolute;
    left: 180px;
    top: 75px;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
    background-color: orange;
}

#content-2 {
    position: absolute;
    left: 40px;
    top: 200px;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
    background-color: #98879A;
}

#content-3 {
    position: absolute;
    left: 400px;
    top: 400px;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
    background-color: #637DBA;
}

@media screen and (max-width:639px) {
    .fd-top-ab {
        position: absolute;
        top: -30%;
    }

    .l-heading {
        font-size: 30px;
        margin-left: 1.3rem;
        font-family: Poppins;
        color: black;
    }

    .enable-center {
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        margin: auto;
        width: 100%;
        border: none;
        min-height: 100vh;
        margin-top: 1.5%;
    }

    .lpbg-img {
        margin-top: 1.5rem;
        width: 25%;
        z-index: -1;
    }

    .lpbg-img_ {
        margin-left: 4rem;
        margin-top: 1.5rem;
        width: 25%;
        z-index: -1;
    }

    .lpbg-img-left {
        margin-left: 4rem;
        margin-top: 1.5rem;
        width: 25%;
        transform: rotate(180deg);
        z-index: -1;
    }

    .lpbg-img-left_ {
        margin-left: 5rem;
        width: 25%;
        transform: rotate(180deg);
        z-index: -1;
    }

    .rightsemicircle {
        float: right;
        position: absolute;
        z-index: -1;
        top: 55%;
        right: 0;
        width: 35%;
    }

    .leftsemicircle {
        float: left;
        position: absolute;
        z-index: -1;
        top: 115%;
        left: 0;
        width: 35%;
    }

    .enable-center-img {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: auto;
        width: 100%;
        border: none;
        min-height: 100vh;
    }

    .l-text {
        font-size: 15px;
        margin-left: 1.3rem;
        font-family: "Poppins";
        color: black;
    }

    .l-growingup {
        font-size: 30px;
        font-family: Poppins;
        color: black;
        font-weight: 600;
    }

    .pop-img {
        width: 100%;
        margin-left: 4%;
    }

    .feddupwork-textheading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #AAAAAA;
        margin-left: 2rem;
    }

    .feddupwork-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        color: #000000;
        margin-left: 2rem;
    }

    .feddupwork-text-left {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        color: #000000;
        margin-left: 2rem;
    }
.grow-card{
    width:80%;
}
    .l-lastupdated {
        font-size: 13px;
        font-family: "Poppins";
        color: #AAAAAA;
        margin-top: 10px;
    }

    .l-card {
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 15px #999;
    }

    .l-card-img {
        width: 2rem;
    }

    .l-card-count {
        font-size: 30px;
        font-family: "Poppins";
        color: #E6570E;
        font-weight: 600;
    }

    .l-card-text {
        font-size: 14px;
        font-family: "Poppins";
        color: #3A3A3A;
    }

    .contentpolicy {
        color: #E6570E;
        font-weight: 600;
        font-size: 20px;
        font-family: "Poppins";
    }

    .contentpolicy-text {
        margin-left: 20%;
        margin-right: 20%;
        font-size: 14px;
        font-style: normal;
        font-family: "Poppins";
    }

    .curvedline {
        width: 500px;
        border: solid 5px #aaaaaa;
        border-color: #aaaaaa transparent transparent transparent;
        border-radius: 50%/100px 100px 0 0;
    }

    #lower-line {
        border: solid 5px grey;
        width: 90%;
        height: 600px;
        border-radius: 50%;
        top: 220%;
        left: 0px;
        transform: rotate(-30deg);
        border-left: none;
        border-top: none;
        border-bottom: none;
        position: absolute
    }

    #upper-line {
        border: solid 5px grey;
        width: 90%;
        height: 600px;
        border-radius: 50%;
        left: 85px;
        transform: rotate(30deg);
        border-right: none;
        border-top: none;
        border-bottom: none;
        position: absolute;
        top: 300%;
    }

    #lower-line1 {
        border: solid 5px grey;
        width: 90%;
        height: 600px;
        border-radius: 50%;
        top: 380%;
        left: 0px;
        transform: rotate(-30deg);
        border-left: none;
        border-top: none;
        border-bottom: none;
        position: absolute;
    }

    /* p #lp-bg-img {
        background-image: url("../images/background-img.png");
        width: 50%;
    } */

    #upper-line1 {
        border: solid 5px grey;
        width: 90%;
        height: 600px;
        border-radius: 50%;
        left: 85px;
        transform: rotate(30deg);
        border-right: none;
        border-top: none;
        border-bottom: none;
        position: absolute;
        top: 460%;
    }

    #lower-line2 {
        border: solid 5px grey;
        width: 92%;
        height: 600px;
        border-radius: 50%;
        top: 530%;
        left: 0px;
        z-index: -1;
        transform: rotate(-40deg);
        border-left: none;
        border-top: none;
        border-bottom: none;
        position: absolute
    }

    #content-1 {
        position: absolute;
        left: 180px;
        top: 75px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50%;
        text-align: center;
        background-color: orange;
    }

    #content-2 {
        position: absolute;
        left: 40px;
        top: 200px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50%;
        text-align: center;
        background-color: #98879A;
    }

    #content-3 {
        position: absolute;
        left: 400px;
        top: 400px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50%;
        text-align: center;
        background-color: #637DBA;
    }
}