.help-card{
    align-items:center;
    text-align: center;
    justify-content: center;
    padding:1rem 0.5rem;
    border:2px solid lightgrey;
    border-radius: 5px;
}
.help-topic{
    font-family: poppins;
    font-size: 18px;
    font-weight: 500;
}
.help-text{
    font-family: poppins;
    font-size: 14px;
    font-weight: 500;
    padding:0.8%;
}
.faq-topic{
    font-family: poppins;
    font-size: 20px;
    font-weight: 600;
    padding:4%;
}
.custom-accordion-style .section-expandable {
    background: transparent;
}
.custom-accordion-style .osui-accordion-item {
    background: transparent;
}
.pricing-p{
    padding:8%;
    height:520px;
}
.free{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
color: #092C4C;
}
.react-icon{
    color: #E6570E;
}
.pricing-ul{
    margin-top: 6px;
    width:38px;
    border:1px solid #E6570E;
    height:1px;
}
.verticalAlign{
  vertical-align: text-top;
}
.pricing-inner-text{
    color:#716C80;
    font-family: Poppins;
    font-size: 14px;
}
.wrap_ {
    display: flex;
    background: transparent;
    width: 100%;
    justify-content: space-between;
  }
  .wrap_ div:last-child {
    margin-left: auto;
  }
  .cursor-pointer{
    cursor: pointer;
  }