#background-company {
    background: #f5f5f5;
    height: 100%;
    min-height: 100vh;
}

.usersfeed-l-r {
    margin-top: 3.7rem;
    margin-left: 8rem;
    margin-right: 7rem;
}

.search-l-r {
    margin-top: 3.7rem;
    margin-left: 10rem;
    margin-right: 9rem;
}

.commentText img {
    width: 200px !important;
    height: 150px !important;
    object-fit: contain !important;
}

.row-width {
    width: 100%;
}

.icon-box {
    width: 29px;
    height: 30px;
    padding: 6px 4px;
    border-radius: 2px;
    border: 1px solid rgb(137, 136, 136);
}

.searchfollow-btn {
    background-color: #E6570E;
    width: 4.9rem;
    height: 30px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 6px;
    padding-top: 5px;
    color: white;
    font-family: poppins;
    font-size: 12px;
    font-weight: 600;
}

.searchfollow-btn:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.searchunfollow-btn {
    background-color: #ffffff;
    width: 4.9rem;
    height: 30px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 6px;
    padding-top: 5px;
    color: #E6570E;
    font-family: poppins;
    font-size: 12px;
    font-weight: 600;
}

.searchunfollow-btn:hover {
    background-color: #ffffff;
    color: #E6570E;
    border-color: #E6570E;
}

.hidden {
    display: none;
}

.row-width-company {
    width: 100%;
    margin-top: -120px;
}

.standalone {
    font-size: 20px;
    font-family: poppins;
    font-weight: 600;
}

.followers {
    font-size: 15px;
    font-family: poppins;
    font-weight: 500;
}

.followers-sm {
    font-size: 12px;
    font-family: poppins;
    color: #AAAAAA;
}

.followers-sm-c {
    font-size: 12px;
    font-family: poppins;
    color: #AAAAAA;
    margin-top: 4px !important;
}

.userfollowers-sm {
    font-size: 18px;
    font-family: poppins;
    color: #000000;
    font-weight: 600;
}

.follow-back-btn {
    background-color: #E6570E;
    width: 6rem;
    height: 28px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 2px;
    padding-top: 4px;
    color: white;
    font-family: poppins;
    font-size: 12px;
}

.follow-back-btn:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.follow-btn {
    background-color: #E6570E;
    width: 6rem;
    height: 24px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 2px;
    padding-top: 2px;
    color: white;
    font-family: poppins;
    font-size: 12px;
}

.follow-btn:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.unfollow-btn {
    background-color: #ffffff;
    width: 6rem;
    height: 24px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 2px;
    padding-top: 2px;
    color: #E6570E;
    font-family: poppins;
    font-size: 12px;
}

.unfollow-btn:hover {
    background-color: #ffffff;
    color: #E6570E;
    border-color: #E6570E;
}

.feddback-company {
    /* overflow-y: auto;
    height: 58dvh; */
    /* overflow-x: hidden; */
    scrollbar-width: none;
    padding: 0 24px;
    margin: 8px -24px;
}

.feddback-company::-webkit-scrollbar {
    /* WebKit */
    display: none;
    -ms-overflow-style: none;
}

.rating-img {
    width: 55%;
    cursor: pointer;
}

.tag-data-fill {
    position: relative;
    top: -29px;
    color: white;
    left: 10px;
}

.tag-data-fill_ {
    position: relative;
    top: -28px;
    color: white;
    left: 10px;
}

.tag-data-empty {
    position: relative;
    top: -29px;
    color: grey;
    left: 10px;
}

.tag-data-empty_ {
    position: relative;
    top: -28px;
    color: grey;
    left: 10px;
}

.family {
    font-family: Poppins;
}

.rating-background {
    width: 65%;
    cursor: pointer;
    background-color: #E6570E;
}

.card-padding {
    margin: 15px;
    border: none !important;
}

.t-today {
    color: #E6570E;
    font-family: "poppins";
    font-weight: 500;
}

.c-rating {
    /* min-height: 60px; */
    color: black;
    font-family: "poppins";
    font-weight: 500;
}

.scroll_interests {
    width: 100%;
    height: 110px;
    display: flex;
    padding: 10px;
}

.interests_main {
    width: 100%;
    height: 120px;
}

.t-tags {
    font-family: "poppins";
    font-weight: 600;
}

#talkbubble {
    width: auto;
    position: relative;
}

#talkbubble:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: -0.9px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 20px solid #FF8B66;
    stroke-width: 30px;
    border-bottom: 12px solid transparent;
}

.tags-btn {
    background-color: #FF8B66;
    width: auto;
    height: 24px;
    border-color: #FF8B66;
    margin: auto;
    border-radius: 2px;
    padding-top: 1px;
    color: white;
    font-family: poppins;
    font-size: 12px;
}

.cursor {
    cursor: pointer;
}

.feed-card {
    align-items: center;
    justify-content: center;
    font-family: poppins;
    padding: 5px;
    font-size: 14px;
}

.feedback-btn {
    padding: 0 1px !important;
}

.text-topproducts {
    font-size: 14px;
    font-family: poppins;
    margin: auto;
    margin-top: 2px;
}

.d-down {
    background-color: white;
    height: 31px;
    margin-left: 38px !important;
}

.no-pages {
    background: rgb(245, 245, 245);
    padding: 2.5rem;
    width: 50vw;
}

.n-pages {
    padding: 2rem 0 2rem 0rem;
}

.no_pages {
    background: rgb(245, 245, 245);
    padding: 2.5rem;
    width: 45vw;
}

.no-pages- {
    background: rgb(245, 245, 245);
    padding: 2.5rem;
    width: 50vw;
}

.no-pages-img {
    width: 70px;
}

.i-icon {
    margin-top: -4px;
}

.productWebsite-link {
    font-size: 12px;
    font-family: Poppins;
}

.tool-text {
    font-family: poppins;
    font-size: 14px;
}

.tooltip-inner {
    background-color: rgb(243, 239, 239);
    color: black;
    padding: 0.5rem;
    border-color: 1px solid black;
    width: auto;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: rgb(243, 239, 239) !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: rgb(243, 239, 239) !important;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: rgb(243, 239, 239) !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: rgb(243, 239, 239) !important;
}

.drop-Down {
    background-color: white;
    height: 31px;
    margin-top: -35px;
}

.d-text {
    font-size: 14px;
    font-family: poppins;
    color: #AAAAAA;
}

.top-products-new {
    display: flex;
    padding: 5px;
}

.top-products-new:hover {
    background-color: #f5f5f5;
}

.card-padding-sm {
    padding: 15px;
    background-color: #f5f5f5;
    height: 96%;
}

.card-padding-sm-c {
    padding: 15px;
    background-color: #f5f5f5;
    height: 91%;
}

.posted {
    font-size: 11px;
    font-family: poppins;
    color: #AAAAAA;
}

.comment {
    font-size: 12px;
    font-family: poppins;
    color: #AAAAAA;
}

.title {
    margin-top: 1px;
    font-size: 16px;
    font-family: poppins;
    color: black;
}

.create-post {
    border: 2px solid #E6570E;
    opacity: 0.3;
    width: 100%;
    margin: 3px;
    padding: 3px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.desc {
    font-size: 13px;
    font-family: poppins;
    color: #AAAAAA;
}

.desc:hover {
    font-size: 13px;
    font-family: poppins;
    color: #AAAAAA;
    text-decoration: none;
}

.suggestion-border {
    border: 2px dashed #E40D9E !important;
    border-radius: 10px;
}

.suggestion-border-accepted {
    border: 2px dashed rgb(93, 246, 101) !important;
    border-radius: 10px;
}

.suggestion-border-pending {
    border: 2px dashed #FFB858 !important;
    border-radius: 10px;
}

.suggestion-border-rejected {
    border: 3px dashed #E6570E !important;
    border-radius: 10px;
}

.suggestion-img-container {
    position: absolute;
    left: -20px;
    top: -20px;
    z-index: 10;
}

/* Subtract */
.subtract {
    box-sizing: border-box;
    position: absolute;
    width: 157.01px;
    height: 34px;
    left: 619.01px;
    top: 925px;
    border: 1px solid #AAAAAA;
}

/* Rectangle 23 */
.rectangle-1 {
    box-sizing: border-box;
    position: absolute;
    width: 159.35px;
    height: 34px;
    left: 616.66px;
    top: 925px;
    background: #55ACEE;
    mix-blend-mode: normal;
    border: 1px solid #AAAAAA;
}

/* Rectangle 157 */
.rectangle-2 {
    position: absolute;
    width: 6px;
    height: 6px;
    left: 638.6px;
    top: 939px;
    background: #C4C4C4;
    border-radius: 3px;
}

.productType {
    font-size: 12px;
    font-family: "poppins";
    font-weight: 600;
}

.companyName {
    font-size: 14px;
    font-family: "poppins";
    font-weight: 600;
}

.drag-drop {
    width: 47dvw;
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 0.5rem 0.5rem 1.3rem 0.5rem;
    cursor: pointer;
}

.i-flex {
    display: flex;
}

.b-image {
    border: 1px solid lightgrey;
    width: 100%;
    height: 35px;
    padding: 0.1rem;
    border-radius: 10px;
    color: grey;
}

.guidelines {
    font-size: 12px;
    font-family: poppins;
}

.search-res-heading {
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins;
    margin-left: 15px;
}

/* .popup-box {
    position: fixed;
    display: inline-block;
    background: #00000050;
    width: 100%;
    height: 120vh;
    top: -6px;
    left: 0;
}

._box {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    max-height: 100vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow-y: auto;
}

.close-icon-new {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.modal-new {
    top: 3rem;
    width: 28%;
    margin-left: 36%;
    margin-right: 36%;
} */

.productName {
    font-weight: 500;
}

.comment_box {
    width: 100%;
    height: 34px;
    margin-top: 10px;
    border: 1px solid #E6570E;
    border-radius: 0px;
    background-color: white;
    color: #E6570E;
}

#comment {
    background-image: url('../../src/images/search.png');
    background-position: 2% 50%;
    background-size: 3%;
    background-repeat: no-repeat;
    z-index: 100;
    padding-top: -10px;
    padding-left: 40px;
    width: 100%;
    height: 34px;
    border: 1px solid #E6570E;
    border-radius: 0px;
}

.c-box {
    border: 3px solid #E6570E;
    background-color: #E6570E;
    width: 38px;
    height: 37px;
    border-radius: 2px;
    margin-top: -1px;
    margin-left: -2px;
}

.c-padding {
    margin: auto;
    border: 2px solid #E6570E;
    height: 38px;
}

.lock {
    padding: 7px 10px;
    width: 30px;
}

.c-text {
    color: #E6570E;
    width: 100%;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    margin-top: 5px;
    height: 5px;
}

.c-comments {
    padding: 10px;
    background: #F5F5F5;
    border-radius: 2px;
    border: none !important;
    font-family: Poppins;
}

.modal-header {
    border-bottom: 0 none;
}

.submit-button {
    background-color: #E6570E;
    border-radius: 30px;
    color: white;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
}

.submit-button:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.submit-button-grey {
    background-color: grey;
    border-radius: 30px;
    color: white;
    border-color: grey;
    font-family: 'Poppins';
    font-size: 15px;
}

.submit-button-grey:hover {
    background-color: grey;
    color: white;
    border-color: grey;
}

.suggestion-box-text {
    font-family: 'Poppins';
    font-size: 15px;
}

.deleteyes-button {
    background-color: #E6570E;
    border-radius: 2px;
    color: white;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
}

.deleteyes-button:hover {
    background-color: #E6570E;
    color: #ffffff;
    border-color: #E6570E;
}

.deleteno-button {
    background-color: #ffffff;
    border-radius: 2px;
    color: #E6570E;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
    width: 7.5rem;
}

.deleteno-button:hover {
    background-color: #ffffff;
    color: #E6570E;
    border-color: #E6570E;
}

.rating-hover {
    width: 30px;
    margin-left: 1px;
}

.rating-hover:hover {
    background-color: #E6570E;
}

.rating-1 {
    color: #E6570E;
}

.rating-2 {
    color: #E6570E;
}

.rating-3 {
    color: #E6570E;
}

.rating-4 {
    color: #E6570E;
}

.rating-5 {
    color: #E6570E;
}

.rating-1:hover {
    background-color: #E6570E;
}

.rating-2:hover {
    background-color: #E6570E;
}

.rating-3:hover {
    background-color: #E6570E;
}

.rating-4:hover {
    background-color: #E6570E;
}

.rating-5:hover {
    background-color: #E6570E;
}

.rating {
    border: none;
    float: left;
}

.rating>input {
    display: none;
}

.rating>label:before {
    margin: 5px;
    cursor: pointer;
    font-size: 2.25em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating>.half:before {
    content: "\f089";
    position: absolute;
}

.rating>label {
    color: #ddd;
    float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
    color: #E6570E;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
    color: #E6570E;
}

.btn-outer-section {
    position: relative;
    border: 2px solid #FFB858;
    border-radius: 12px !important;
}

.btn-inner-section {
    background-color: #E6570E;
    color: #ffffff;
    /* width: 110px;
    height: 35px; */
    border: 2px solid #E6570E;
    border-radius: 10px !important;
    position: relative;
    z-index: 10;
}

.review-card {
    border-width: 0px 0px 2px 2px;
    border-style: dashed;
    border-color: #E6570E;
    border-radius: 12px;
}

.rating-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    margin: 1.5rem 0;
}

.rating-text {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
}

.btn-outer {
    position: relative;
    width: 148px;
    height: 39px;
}

.btn-2 {
    background-color: #E6570E;
    color: #ffffff;
    width: 148px;
    height: 39px;
    border-radius: 10px;
    position: relative;
    z-index: 10;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.btn-2:focus {
    color: #ffffff;
    outline: none;
    box-shadow: none;
}

.btn-1 {
    position: absolute;
    inset: 0;
    background-color: #FFB858;
    width: 150px;
    height: 39px;
    border: 2px solid #FFB858;
    border-radius: 10px;
    transform: rotate(6deg);
    margin-left: 2px;
}

.rating-container {
    display: flex;
    align-items: start;
    padding: 30px 30px 10px 30px;
    gap: 40px;
}

.rating-comment-container {
    flex-grow: 1;
}

.comment-details {
    margin: 0 1.5rem;
    text-align: right;
    width: 100%;

}

.rating-input {
    background: #ffffff;
    border: 2px solid #FFB633;
    border-radius: 10px;
    margin-top: 1.5rem !important;
    padding: 20px 70px;
    gap: 20px;
}

.inp-1 {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
}

.inp-2 {
    align-items: center;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
}

/* .user-feedback {
    border-width: 2px 2px 0px 0px;
    border-style: dashed;
    border-color: #FFB858;
    border-radius: 8px;
} */

.feedback-inp {
    background-color: #ffffff;
    border: 2px solid #FFB633;
    border-radius: 10px;
    min-height: 100px;
    gap: 30px;
    margin-top: 1.5rem;
}

.feedback-details {
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hide-element {
    display: none;
    animation: hideElement 1s ease-in-out;
    transition: all 1s ease-in-out;
}

@keyframes hideElement {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 0;
    }

    100% {
        display: none;
    }

}

/* .fixed {
    position: fixed;
    top: 30vh;
    margin-left: 56vw;
    max-width: max-content;
} */

.card-none {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: none;
    border: none;
    border-radius: 0;
}

.border-gradient {
    background: linear-gradient(white, white) padding-box, linear-gradient(to bottom right, #FFB633, #E6570E);
    border-radius: 10px;
    border-width: 3px;
    border-color: transparent;
}

.fixed-buttons-company {
    position: fixed;
    top: 60px;
    z-index: 20;
    /* overflow-y: hidden; */
    border-radius: 10px;
    min-width: 50vw;
    margin-left: 5rem;
}
.companyDetails{
    position: relative;
    z-index: 10;
    border-bottom: 2px solid #E6570E;
}

@media (max-width: 1280px) {
    .fixed-buttons-company {
        min-width: 59vw;
        margin-left: 0;

    }

    .fixed-side-company {
        max-width: 28vw;
        margin-left: 53rem;
    }
}

.fixed-side-company {
    position: fixed;
    top: 8vh;
    z-index: 20;
    overflow-y: hidden;
}