#background-admin {
    background: rgb(245, 245, 245);
    height: 100%;
    min-height: 100vh;
}

#background-announcement {
    background: rgb(245, 245, 245);
    height: 100vh;
}

.usersfeed-l-r {
    margin-top: 5rem !important;
    margin-left: 8rem !important;
    margin-right: 7rem !important;
}

.row-width {
    width: 100%;
}

.row-width-company {
    width: 100%;
    margin-top: -120px;
}

.standalone {
    font-size: 20px;
    font-family: poppins;
    font-weight: 500;
}

.followers {
    font-size: 15px;
    font-family: poppins;
    font-weight: 500;
    margin-top: 2px;
}

.followers-sm {
    font-size: 10px;
    font-family: poppins;
    color: #AAAAAA;
}

.follow-btn {
    background-color: #E6570E;
    width: 6rem;
    height: 24px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 2px;
    padding-top: 2px;
    color: white;
    font-family: poppins;
    font-size: 12px;
}

.follow-btn:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.unfollow-btn {
    background-color: #ffffff;
    width: 6rem;
    height: 24px;
    border-color: #E6570E;
    margin: auto;
    border-radius: 2px;
    padding-top: 2px;
    color: #E6570E;
    font-family: poppins;
    font-size: 12px;
}

.unfollow-btn:hover {
    background-color: #ffffff;
    color: #E6570E;
    border-color: #E6570E;
}

.rating-img {
    width: 55%;
    cursor: pointer;
}

.rating-background {
    width: 65%;
    cursor: pointer;
    background-color: #E6570E;
}

.cursor {
    cursor: pointer;
}

.card-padding {
    margin: 15px;
    border: none !important;
}

.t-today {
    color: #E6570E;
    font-family: "poppins";
}

.scroll_interests {
    width: 100%;
    height: 110px;
    display: flex;
    padding: 10px;
}

.interests_main {
    width: 100%;
    height: 120px;
}

.t-tags {
    font-family: "poppins";
}

.circle:before {
    content: ' \25CF';
    font-size: 12px;
    float: left;
    margin-left: -12px;
}

#talkbubble1 {
    width: 7.6rem;
    position: relative;
}

#talkbubble1:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: -0.9px;
    width: 0;
    height: 0;
    border-radius: 20%;
    border-top: 12px solid transparent;
    border-right: 12px solid #FF8B66;
    border-bottom: 12px solid transparent;
}

.tags_btn {
    background-color: #FF8B66;
    width: 7.6rem;
    height: 24px;
    border-color: #FF8B66;
    margin: auto;
    border-radius: 2px;
    padding-top: 2px;
    color: white;
    font-family: poppins;
    font-size: 12px;
}

.feddback-admin {
    overflow-y: scroll;
    height: 49vh;
    overflow-x: hidden;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
}

.feddback-admin::-webkit-scrollbar {
    /* WebKit */
    display: none;
    -ms-overflow-style: none;
}

.feddback-admin-announcement {
    overflow-y: sccroll;
    height: 42vh;
    overflow-x: hidden;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
}

.feddback-admin-announcement::-webkit-scrollbar {
    /* WebKit */
    display: none;
    -ms-overflow-style: none;
}

.r-comment {
    width: 80%;
}

.tags_btn:hover {
    background-color: #FF8B66;
    border-color: #FF8B66;
    color: white;
}

.feed-card {
    align-items: right;
    justify-content: center;
    font-family: poppins;
    padding: 5px;
    font-size: 14px;
}

.text-topproducts {
    font-size: 14px;
    font-family: poppins;
    margin: auto;
    margin-top: 2px;
}

.d-down {
    background-color: white;
    height: 31px;
    margin-left: 38px !important;
}

.drop-Down-a {
    background-color: white;
    height: 31px;
    margin-top: -0.3rem;
}

.status-drop-Down-a {
    background-color: white;
    height: 31px;
    margin-top: -1.7rem;
    margin-bottom: 0.5rem;
    color: grey;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    display: flex;
    font-family: "poppins";
    font-size: 12px;
    border-radius: 6px;
    justify-content: center;
}

.m-tag-left_ {
    margin-left: -10px;
}

.d-text {
    font-size: 14px;
    font-family: poppins;
    color: #AAAAAA;
}

.top-products-new {
    display: flex;
    padding: 5px;
}

.top-products-new:hover {
    background-color: #f5f5f5;
}

.card-padding-sm {
    padding: 15px;
    background-color: #f5f5f5;
    height: 96%;
    border: none !important;
}

.posted {
    font-size: 11px;
    font-family: poppins;
    color: #AAAAAA;
}

.comment {
    font-size: 12px;
    font-family: poppins;
    color: #AAAAAA;
}

.title {
    margin-top: 1px;
    font-size: 16px;
    font-family: poppins;
    color: black;
}

.desc {
    font-size: 13px;
    font-family: poppins;
    color: #AAAAAA;
}

.suggestion-border {
    border: 1px solid white;
}

.suggestion-border-accepted {
    border: 2px solid green;
}

.suggestion-border-pending {
    border: 2px solid orange;
}

.suggestion-border-rejected {
    border: 2px solid red;
}

.guidelines {
    font-size: 12px;
    font-family: poppins;
}

/* #span {
    clip-path: polygon( 0% 0%, calc(100% - 0.4em) 0%, 100% 50%, calc(100% - 0.4em) 100%, 0% 100%);
  }
  #span {
    font-size: clamp(1em, 5vmax, 40px);
    padding-right: 0.5em;
    color: white;
    float: left;
    clear: left;
    margin: 0.1em;
  }
  
  #span:nth-child(1) {
    border: 1px solid#018133;
    padding-left: 2em;
  }
  
  #span:nth-child(2) {
    background: #27B432;
    padding-left: 2.8em;
  } */


/* #base {
    background: red;
    display: inline-block;
    height: 55px;
    margin-left: 20px;
    margin-top: 55px;
    position: relative;
    width: 100px;
  }
  #base:before {
    border-bottom: 35px solid red;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -35px;
    width: 0;
  }
  #pointer {
    width: 200px;
    height: 40px;
    position: relative;
    border:1px solid red;
  }
  #pointer:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointer:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left:5px solid red;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  } */

/* .popup-box {
    position: fixed;
    display: inline-block;
    background: #00000050;
    width: 100%;
    height: 120vh;
    top: -6px;
    left: 0;
}

._box {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    max-height: 100vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow-y: auto;
}

.close-icon-new {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.modal-new {
    top: 3rem;
    width: 28%;
    margin-left: 36%;
    margin-right: 36%;
} */

.comment_box {
    width: 100%;
    height: 34px;
    margin-top: 10px;
    border: 1px solid #E6570E;
    border-radius: 0px;
    background-color: white;
    color: #E6570E;
}

#comment {
    background-image: url('../../src/images/search.png');
    background-position: 2% 50%;
    background-size: 3%;
    background-repeat: no-repeat;
    z-index: 100;
    padding-top: -10px;
    padding-left: 40px;
    width: 100%;
    height: 34px;
    border: 1px solid #E6570E;
    border-radius: 0px;
}

/* .c-box {
    border: 1px solid #E6570E;
    background-color: #E6570E;
    width: 40px;
}

.c-padding {
    margin: 0px;
    border: 2px solid #E6570E;
}

.lock {
    padding: 10px;
    width: 30px;
} */

.c-text {
    color: #E6570E;
    width: 100%;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    margin-top: 5px;
    height: 5px;
}

.edit-feddup {
    background: #F5F5F5;
    border-radius: 2px;
    border: none !important;
    font-family: Poppins;
    padding: 10px;
    font-size: 13px;
}

.c-comments {
    padding: 10px;
    background: #F5F5F5;
    border-radius: 2px;
    border: none !important;
    font-family: Poppins;
}

.modal-header {
    border-bottom: 0 none;
}

.submit-button {
    background-color: #E6570E;
    border-radius: 30px;
    color: white;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
}

.submit-button:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.publish-button {
    background-color: #FF8B66;
    border-radius: 4px;
    width: 8rem;
    margin-right: 1.8%;
    color: white;
    border-color: #FF8B66;
    font-family: 'Poppins';
    font-size: 15px;
}

.country-family {
    color: #FF8B66;
    font-size: 20px;
    font-family: "poppins";
    font-weight: 500;
    padding: 10px 10px 10px 0px;
}

.country-l {
    margin-left: 15%;
}

.publish-button:hover {
    background-color: #FF8B66;
    color: white;
    border-color: #FF8B66;
}

.publish-button-grey {
    background-color: grey;
    border-radius: 4px;
    width: 8rem;
    margin-right: 1.8%;
    color: white;
    border-color: grey;
    font-family: 'Poppins';
    font-size: 15px;
}

.publish-button-grey:hover {
    background-color: grey;
    color: white;
    border-color: grey;
}

.cancel-button {
    background-color: #ffffff;
    border-radius: 4px;
    width: 8rem;
    margin-right: 1.8%;
    color: #FF8B66;
    border-color: #FF8B66;
    font-family: 'Poppins';
    font-size: 15px;
}

.cancel-button:hover {
    background-color: #ffffff;
    color: #FF8B66;
    border-color: #FF8B66;
}

.suggestion-box-text {
    font-family: 'Poppins';
    font-size: 15px;
}

.deleteyes-button {
    background-color: #E6570E;
    border-radius: 2px;
    color: white;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
}

.deleteyes-button:hover {
    background-color: #E6570E;
    color: #ffffff;
    border-color: #E6570E;
}

.reportyes-button {
    background-color: #E6570E;
    color: #ffffff;
    border-radius: 2px;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
    width: 7.5rem;
}

.reportyes-button:hover {
    background-color: #E6570E;
    color: #ffffff;
    border-color: #E6570E;
}

.deleteno-button {
    background-color: #ffffff;
    border-radius: 2px;
    color: #E6570E;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
    width: 7.5rem;
}

.deleteno-button:hover {
    background-color: #ffffff;
    color: #E6570E;
    border-color: #E6570E;
}

.pin-pop-text {
    font-family: "poppins";
    font-size: 14px;
}

.card-padding-ad-dashboard {
    margin: 1rem;
    border: none !important;
}

.feedback-left-align {
    float: left;
    font-size: 20px;
    color: #E6570E;
    font-family: "poppins";
    font-weight: 600;
}

.feedback-count-right-align {
    float: right;
    font-size: 50px;
    color: #FF8B66;
    font-family: "poppins";
    font-weight: 600;
    margin-top: -0.7rem;
}

.feedback-f-family {
    font-size: 14px;
    font-family: "poppins";
    padding: 4px;
}

.drag-drop-announcement {
    display: block;
}

select {
    appearance: none;
    -webkit-appearance: none;
    background: url('../images/drop-dwon-arrow.svg') no-repeat right center;
    padding-right: 2rem;
}

.sel select {
    appearance: none;
    -webkit-appearance: none;
    background: url('../images/drop-down-arrow-small.svg') no-repeat right center;
}

.products-container {
    position: absolute;
    background-color: white;
    border-radius: 100%;
    max-width: max-content;
    padding: 1rem;
    top: -34px;
    left: -34px;
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 10px 6rem;
}

.btn-2.disabled {
    background: #B73E00;
    opacity: 100;
}

.table {
    border-bottom: 0px !important;
}

.table th,
.table td {
    border: 1px !important;
}

.modal-header,
.modal-body,
.modal-footer {
    border: 0px !important;
    padding: 1.5rem !important;
}

@media (min-width: 576px) {
    .modal-dialog-add {
        max-width: 45vw;
        margin: 1.75rem auto;
    }
}

select.reviews-select {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    padding-right: 2rem;
}

@media (min-width: 576px) {
    .modal-highlighted-reviews {
        max-width: 60vw;
        margin: 1.75rem auto;
    }
}

.modal-newForm {
    max-width: 40%;
    margin: 1.75rem auto;
}

.border-gray {
    border-color: rgb(156 163 175 / 1) !important;
}

.dashed-border {
    border: 2px dashed #AAAAAA !important;
    border-color: #AAAAAA;
}
.checkbox-color[type="checkbox"]:checked{
    background-color: #FF8B66 !important;
}

.delete:hover path {
    fill: red;
}
.edit:hover path {
    fill: green;
}
.add-rating-moodal{
    width: 70vw;
    height: 60vh;
}