.react-horizontal-scrolling-menu--scroll-container {
    order: 1;
    flex-basis: 100%;
    flex-shrink: 0;
}

.react-horizontal-scrolling-menu--wrapper {
    flex-wrap: wrap;
}

.arrows-container {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    padding: 10px;
}

.arrows {
    display: flex;
    flex-basis: 15%;
    justify-content: space-evenly;
}