.login {
    width: 450px;
    margin-left: 20px;
    margin-right: 20px;
}

.logo-login {
    width: 5%;
    margin-top: 1.5rem;
    margin-left: 2rem;
    cursor: pointer;
}

.border {
    border: 1px solid black !important;
}

.error-border {
    border: 1px solid red !important;
}

.forgot-password {
    font-size: 16px;
    color: #E6570E;
}

.b-color {
    border: 2px solid #E6570E;
    border-radius: 20px;
}

.forgot-password:hover {
    color: #E6570E;
}

.img-login {
    width: 91%;
    height: 347px;
    margin-left: 94px;
    margin-top: 40px;
    padding: 0 48px;
    object-fit: contain;
}

input[type="radio"]:checked:before {
    background: #E6570E;
    width: 4px;
}

.pricing-card {
    padding: 2.1rem 10px;
    text-align: center;
}

.plans-name-align {
    padding: 1.2rem 0;
    text-align: center;
}

.plans-text-align {
    padding: 10px 3px;
    text-align: left;
    font-size: 12px;
}

.pricing-btn {
    background-color: #AAAAAA;
    border-radius: 0px;
    color: white;
    width: 5rem;
    border-color: #AAAAAA;
    font-family: 'Poppins';
    font-size: 12px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.pricing-m-btn {
    background-color: white;
    border-radius: 0px;
    color: #AAAAAA;
    border-color: #AAAAAA;
    font-family: 'Poppins';
    font-size: 12px;
    width: 5rem;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.pricing-m-btn:hover {
    color: #AAAAAA;
}

.pricing-btn:hover {
    background-color: #AAAAAA;
    color: white;
    border-color: #AAAAAA;
}

.pricing-y-btn {
    background-color: white;
    border-radius: 0px;
    color: #AAAAAA;
    border-color: #AAAAAA;
    font-family: 'Poppins';
    font-size: 12px;
    width: 5rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.digit-width {
    width: 43px;
    height: 55px;
    color: black;
    border-radius: 6px;
    height: 3.2rem;
    margin-left: 2%;
    margin-right: 2%;
    font-weight: semi-bold;
    font-size: 25px;
}

.digit-width1 {
    width: 43px;
    height: 55px;
    color: black;
    border-radius: 6px;
    height: 3.2rem;
    margin-right: 2%;
    font-weight: semi-bold;
    font-size: 25px;
}

.verify {
    display: flex;
}

.verify1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-y-btn:hover {
    color: #AAAAAA;
}

.pricing-my-btn {
    background-color: #AAAAAA;
    border-radius: 0px;
    color: white;
    border-color: #AAAAAA;
    font-family: 'Poppins';
    font-size: 12px;
    width: 5rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.pricing-my-btn:hover {
    background-color: #AAAAAA;
    color: white;
    border-color: #AAAAAA;
}

.img-signup {
    width: 92%;
    height: 347px;
    margin-left: 40px;
    margin-top: 40px;
}

.account-link {
    color: grey;
    font-family: poppins;
    margin-top: 50px;
}

.forgot-top {
    margin-top: 20px;
    color: black;
}

.showPassword {
    position: sticky;
    float: right;
    margin-top: -2.35rem;
    padding: 5px;
    margin-right: 0.5rem;
    cursor: pointer;
}

.help {
    font-weight: 600;
    font-family: 'Poppins';
    margin-right: 2rem;
    margin-top: 2rem;
    float: right;
}

.account-link1 {
    color: grey;
    font-family: poppins;
    margin-top: 40px;
}

.top_ {
    margin-top: 30px;
}

.font_family {
    font-family: Poppins;
}

.forgot_width {
    width: 90%;
}

.copyright {
    font-size: 14px;
    font-weight: 200 !important;
    color: #333333;
    font-family: 'Poppins';
}

.login_button {
    background-color: #E6570E;
    border-radius: 0px;
    color: white;
    border-color: #E6570E;
    font-family: 'Poppins';
    font-size: 15px;
}

.login_button:hover {
    background-color: #E6570E;
    color: white;
    border-color: #E6570E;
}

.welcomeFeddup-text {
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 25px;
}

.forgotImg {
    width: 35%;
}

.forgot-text {
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 25px;
    margin-top: 1.5rem;
}

.forgot-text-sm {
    font-family: 'Poppins';
    /* font-size: 14px; */
}

.login-here {
    font-family: 'Poppins';
    font-size: 14px;
    color: #FF8B66;
    text-decoration: none;
    cursor: pointer;
}

.link {
    text-decoration: none !important;
}

.error {
    color: red;
    font-size: 8px;
    font-family: Poppins;
    font-weight: normal !important;
    height: 0px;
    float: left;
}

.errormsg {
    color: red;
    font-size: 8px;
    font-family: Poppins;
    font-weight: normal !important;
    height: 0px;
}

.container {
    width: 120%;
}

.progressbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    counter-reset: step;
}

.progressbar li {
    list-style: none;
    display: inline-block;
    width: 33.33%;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.progressbar li:before {
    content: "";
    counter-increment: "";
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #ddd;
}

.progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.current {
    color: #FFB858;
}

.progressbar li.current:before {
    border-color: #FFB858;
    background: #FFB858;

}

.progressbar li.active {
    color: #E6570E;
}

.progressbar li.active:before {
    border-color: #E6570E;
    background: #E6570E;
}

.progressbar li.active+li:after {
    background-color: #E6570E;
}

.plan-p {
    margin-top: 0.5rem;
    float: right;
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
}

.cards-width {
    width: 30%;
}

@media screen and (max-width:960px) {
    .cards-width {
        width: 80%;
    }

    .login {
        width: 100%;
        margin-left: 15px;
        margin-right: 10px;
    }

    .logo-login {
        width: 15%;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
        cursor: pointer;
    }

    .img-login {
        width: 92%;
        height: 75%;
        margin-left: 30px;
        object-fit: contain;
    }

    .img-signup {
        width: 92%;
        height: 75%;
        margin-left: 40px;
        margin-top: 40px;
    }
}

.login-btn-1 {
    width: 111px !important;
    height: 30px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 18px !important;
}

.login-btn-2 {
    width: 110px !important;
    height: 30px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 4px !important;
}

footer .copyright {
    font-size: 14px;
    font-weight: 200 !important;
    color: #333333;
    font-family: 'Poppins';
    position: absolute;
    width: 100%;
    line-height: 40px;
    text-align: center;
}