#background {
    background: rgb(245, 245, 245);
    height: 100%;
    min-height: 93.5vh;
}

#background-feed {
    background: rgb(245, 245, 245);
    height: 93vh;
}

.usersfeed-l-r {
    margin-top: 3rem;
    margin-left: 8rem;
    margin-right: 1rem;
}

.row-width {
    width: 100%;
}

.card-padding {
    margin: 5px 10px 5px 10px;
    border: none !important;
}

.pnf-img {
    width: 60%;
    height: 60%;
    margin-left: 7%;
    margin-right: 0%;
}

.pnf-enable-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    border: none;
    min-height: 95vh;
    margin-top: -5%;
}

.pnf-text {
    font-family: "poppins";
    font-weight: 600;
    font-size: 30px;
}

.dropdown-toggle::after {
    display: none;
}

.status-user-drop-Down-a {
    background-color: white;
    height: 31px;
    margin-top: 0.3rem;
    color: grey;
    font-size: 12px;
    font-family: "poppins";
}

.t-today {
    color: #E6570E;
    font-family: "poppins";

}

.trending-today {
    color: #E6570E;
    font-family: "poppins";
    font-weight: 600;
    font-size: 20px;

}

.no-details-text {
    font-family: "poppins";
}

.cards_width_col {
    max-width: 57.5vw;
    border: none !important;
}

.cards_width-user {
    max-width: 50vw;
}

.cards_width {
    max-width: 50vw;
    border: none !important;
}

.cards_width-trending {
    max-width: 50vw;
    border: none !important;
}

.share_ {
    background-color: #e3e2e2;
    border-radius: 6px;
    padding: 6px 10px 8px 10px;
}

.trending-img_ {
    width: 90px;
    margin-top: 5px;
    z-index: -1;
    background-color: white;
}

.border-none {
    border: none !important;
}

.scroll_interests {
    width: 100%;
    height: 120px;
    padding: 10px;
}

.trending-today-icon {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.product-name {
    color: black;
    font-size: 14px;
    font-family: Poppins;
}

.interests_main {
    width: 100%;
    height: 125px;
}

.t-tags {
    font-family: "poppins";
}

.likes {
    font-size: 14px;
    font-family: poppins;
}

#talkbubble {
    width: auto;
    position: relative;
}

#talkbubble-select {
    width: auto;
    position: relative;
}

#talkbubble-unselect {
    width: auto;
    position: relative;
}

/* #talkbubble:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: -0.9px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 20px solid #FF8B66;
    stroke-width: 30px;
    border-bottom: 12px solid transparent;
} */
#talkbubble:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: -0.9px;
    width: 0;
    height: 0;
    border-radius: 20%;
    border-top: 12px solid transparent;
    border-right: 12px solid #FF8B66;
    border-bottom: 12px solid transparent;
}

.tags-btn {
    background-color: #FF8B66;
    width: auto;
    height: 24px;
    border-color: #FF8B66;
    margin: auto;
    border-radius: 2px;
    padding-top: 2px;
    color: white;
    font-family: poppins;
    font-size: 12px;
}

.feddback-col {
    overflow-y: auto;
    height: 57vh;
    width: 100%;
    scrollbar-width: none;
}

.feddback-col::-webkit-scrollbar {
    /* WebKit */
    display: none;
    -ms-overflow-style: none;
}

.tags-data-fill {
    position: relative;
    top: -26px;
    color: white;
    left: 6px;
    font-family: Poppins;
    font-size: 14px;
}

.tags-data-fill_ {
    position: relative;
    top: -25px;
    color: white;
    left: 6px;
    font-family: Poppins;
    font-size: 13px;
}

.m-tag-left {
    margin-left: -15px;
}

.previous {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    z-index: 1;
    color: #E6570E;
    cursor: pointer;
}

.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    z-index: 1;
    color: #E6570E;
    cursor: pointer;
}

.trending-today-bg {
    width: 100%;
    background-color: white;
    display: flex;
}

.scroll-cir-icon {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 2px solid lightgrey;
    padding: 8px;
}

.tags-btn:hover {
    background-color: #FF8B66;
    color: white;
    border-color: #FF8B66;
}

.tags-btn-unselect {
    background-color: #ffffff;
    width: auto;
    height: 24px;
    border-color: grey;
    margin: auto;
    border-radius: 2px;
    padding-top: 1px;
    color: #AAAAAA;
    font-family: poppins;
    font-size: 12px;
}

.tags-btn-unselect:hover {
    background-color: #ffffff;
    color: #AAAAAA;
    border-color: grey;
}

#talkbubble-select:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: -0.9px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid #FF8B66;
    border-bottom: 12px solid transparent;
}

#talkbubble-unselect:before {
    content: "";
    position: absolute;
    right: 99.8%;
    top: -0.9px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid lightgrey;
    border-bottom: 12px solid transparent;
}

.feed-card {
    /* align-items: center;
    justify-content: center;
    font-family: poppins;
    padding: 5px;
    font-size: 14px;
    color: #AAAAAA; */
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    display: flex;
    cursor: pointer;
    font-size: 17px;
    font-family: "poppins";
    border-radius: 6px;
    justify-content: center;
    /* box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff; */
}

.feed-card-a {
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    display: flex;
    font-family: "poppins";
    font-size: 17px;
    border-radius: 6px;
    justify-content: center;
}

/* .feed-card-a {
    align-items: center;
    justify-content: center;
    font-family: poppins;
    padding: 5px;
    font-size: 14px;
    color: black;
} */

/* .feed-card:hover {
    align-items: center;
    justify-content: center;
    font-family: poppins;
    padding: 5px;
    font-size: 14px;
    color: black;
} */

.text-topproducts {
    font-size: 14px;
    font-family: poppins;
    margin: auto;
    margin-top: 2px;
}

.editor_box {
    min-height: 200px;
    background-color: #ffffff;
}

.element-1 {
    box-shadow:
        12px 12px 16px 0 rgba(0, 0, 0, 0.25),
        -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

.d-down {
    background-color: white;
    height: 31px;
    margin-right: 1vw !important;
}

.d-text {
    font-size: 14px;
    font-family: "poppins";
    color: #AAAAAA;
}

.top-products-new {
    display: flex;
    padding: 5px;
}

.top-products-new:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.card-padding-sm {
    padding: 10px 6px 6px 6px;
    background-color: #f5f5f5;
    border: none !important;
}

.f-color {
    color: #FFFFFF;
    background-color: #E6570E;
}

.f-color-g {
    color: #000000;
    font-family: "poppins";
}

.posted {
    font-size: 11px;
    margin-top: -4px;
    font-family: poppins;
    color: #AAAAAA;
}

.comment {
    font-size: 12px;
    font-family: poppins;
    color: #AAAAAA;
}

.title {
    margin-top: 1px;
    font-size: 14px;
    font-family: poppins;
    color: #656565;
}

.desc {
    font-size: 13px;
    font-family: poppins;
    color: #AAAAAA;
}

.guidelines {
    /* position: sticky;
    bottom: 10%; */
    font-size: 12px;
    font-family: poppins;
}

.tag-card {
    border: 1px solid lightgrey;
    width: 100%;
    padding: 18px 20px 0px 20px;
}

.rectangle {
    height: 28px;
    width: auto;
    background-color: #E6570E;
    color: white;
    font-family: "poppins";
    font-size: 16px;
}

.triangle-left {
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-right: 28px solid #E6570E;
    border-bottom: 14px solid transparent;
    color: white;
    font-size: 16px;
}

.feddback-company-userfeed {
    padding: 0 25px;
    margin: 8px -12px;
}

.fixed-buttons {
    position: fixed;
    top: 60px;
    z-index: 30;
    overflow-y: hidden;
    border-radius: 10px;
    max-width: 52vw;
}

.fixed-side {
    position: fixed;
    top: 8vh;
    z-index: 20;
    overflow-y: hidden;
}

.suggestion-margin {
    margin-top: -1.5rem !important;
}