/* .navbar-logo {
    margin-left: 3rem;
    cursor: pointer;
    width: 3.8%;
    margin-bottom: -4rem;
    margin-top: 0rem;
}

.nav-height {
    height: 3.5rem;
}

.search_box {
    padding-top: -20px;
    width: 300px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 20px;
}

#search {
    background-image: url('../../src/images/search.png');
    background-position: 96% 50%;
    background-size: 6%;
    background-repeat: no-repeat;
    z-index: 100;
    margin-left: 60px;
    padding-top: -20px;
    width: 300px;
    height: 35px;
    border: 1px solid grey;
    border-radius: 20px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    padding: 10px;
    list-style: none;
    text-align: center;
    text-decoration: none;
    width: 100vw;
    justify-content: end;
    margin-right: 2 rem;
    margin-top: -.5rem;
    font-size: small;
}

.nav-links {
    color: black;
    transition: all 0.2s ease-out;
    padding: 1.2rem;
}

.nav-marginright {
    margin-right: 31.5rem;
}

.nav-marginright1 {
    margin-right: 35rem;
}

.nav-links-loginbtn {
    background-color: #BDA083;
    width: 6.7rem;
    height: 25px;
    border-color: #BDA083;
    border-radius: 30px;
    text-align: center;
    margin: auto;
    margin-left: 2.2rem;
    padding-top: 1px;
    color: white;
    font-size: small;
}

.btn-jelly {
    background-color: #4FD84F;
    border-color: 5px solid black;
    font-size: small;
}

.btn-login {
    background-color: #BDA083;
    border-color: #BDA083;
    color: white;
    font-size: small;
}

.nav-links-jellybtn {
    background-color: #4FD84F;
    width: 6.7rem;
    height: 30px;
    border-color: 10px solid black;
    border-radius: 30px;
    text-align: center;
    margin: auto;
    margin-right: 2rem;
    color: white;
    padding-top: 3px;
}

.nav-links-jellybtn:hover {
    background-color: #4FD84F;
    border-color: 10px solid black;
    color: white;
}

.nav-links-loginbtn:hover {
    background-color: #BDA083;
    border-color: #BDA083;
    color: white;
}

.nav-links:hover {
    color: #4FD84F;
    transition: all 0.2s ease-out;
    text-decoration: none;
}

.fa-bars {
    color: black;
    margin-top: -0.5rem;
}

.menu-icon {
    display: none;
}

@media screen and (max-width:960px) {
    .NavBarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background-color: rgb(204, 200, 200);
        left: 0;
        top: 165px;
        height: 22rem;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 0.5rem;
        width: 100%;
        display: table;
    }

    .navbar-logo {
        position: absolute;
        left: -2rem;
        margin-top: -2.5rem;
        transform: translate(25%, 50%);
        width: 10%;
    }

    .nav-height {
        height: 4.5rem;
        box-shadow: 0px 1px 10px #999;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: black;
        font-size: 2rem;
        margin-top: -5px;
    }

    .nav-links-jellybtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        width: 9rem;
        height: auto;
        background: #4FD84F;
        text-decoration: none;
        color: white;
        border-color: #4FD84F;
        padding-top: 5px;
        padding-bottom: 8px;
        margin-bottom: 5px;
    }

    .nav-links-loginbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        width: 9rem;
        height: auto;
        background: #BDA083;
        ;
        text-decoration: none;
        color: white;
        border-color: #BDA083;
        padding-top: 5px;
        padding-bottom: 9px;
    }
} */
.navbar-logo {
    margin-left: 2.5rem;
    cursor: pointer;
    margin-top: -2px;
    width: 16%;
}

.nav-height {
    height: 3.8rem;
    background-color: white;
}

.search_box {
    width: 360px;
    height: 34px;
    margin-top: 1.5rem;
    border: 1px solid grey;
    border-radius: 20px;
}
#search {
    background-image: url('../../src/images/search.png');
    background-position: 2.5% 50%;
    background-size: 4.5%;
    background-repeat: no-repeat;
    z-index: 100;
    margin-left: 50px;
    padding-top: -20px;
    padding-left: 32px;
    width: 360px;
    height: 34px;
    border: none;
    background-color:rgb(245,245,245);
    border-radius: 10px;
    font-family: poppins;
}
.search_box_help {
    width: 280px;
    height: 45px;
    border: 1px solid grey;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.bg-img {
    background-image: url("../../src/images/background-img.png");
    min-height: 160px;
    margin-top: 3.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .container-help {
    position: absolute;
    left:30%;
    right:30%;
    margin: 3.7rem 0.5rem;
    background-color: white;
    border-radius: 5px;
  }
#search_help {
    background-image: url('../../src/images/search.png');
    background-position: 5% 50%;
    background-size: 4%;
    background-repeat: no-repeat;
    z-index: 100;
    padding-top: -20px;
    padding-left: 14%;
    width: 100%;
    height: 45px;
    border: 1px solid lightgrey;
    background-color: #F5F5F5 !important;
    border-radius: 5px;
    font-family: poppins;
}
.nav-profile-text-sm {
    font-size: 12px;
    font-family: "poppins";
    color: grey;
}

.closebtn {
    position: absolute;
    top: -5px;
    right: 5px;
    font-size: 26px;
    margin-left: 50px;
    cursor: pointer;
}
.btn-feddup {
    background-color: #E6570E;
    border-color: 5px solid black;
    font-size: small;
}

.nav-links-feddupbtn {
    background-color: #E6570E;
    width: 9rem;
    height: 30px;
    border-color: 10px solid black;
    border-radius: 30px;
    text-align: center;
    margin-right: 2rem;
    color: white;
    padding-top: 4px;
    font-family: Poppins, sans-serif;
}

.nav-links-feddupbtn:hover {
    background-color: #E6570E;
    border-color: 10px solid black;
}
.link {
    text-decoration: none;
    color: #090909;
    font-family: "poppins";
}

.link:hover {
    color: #090909;
}
.companyName{
    color: black;
    font-family: "poppins";  
    font-size: 25px;
}
.card-profile {
    position: absolute;
    top: 3.8rem;
    padding: 0.6rem;
    width: auto;
    right: 3.2rem;

}
.abc:focus,.abc:active {
    outline: none !important;
    box-shadow: none;
 }
#card-search {
    border:none;
    min-width: 362px;
    position: absolute;
    top: 3.1rem;
    padding: 0.6rem;
    width: auto;
    right: 11%;
    display:block;
    content: '';
}
#notification-search {
    border: 1px solid #FFB633;
    min-width: 362px;
    position: absolute;
    top: 4rem;
    padding: 0.6rem;
    width: auto;
    right: 8%;
    display:block;
    content: '';
    max-height: 340px;
    overflow-y: auto;
}


/* #search-bar {
    width: 300px;
    color: white;
    border-radius: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    background-color: #2d2d2d;
    height: 35px;
    background-image: url('../../src/images/search.png');
    background-position: 10px center;
    background-repeat: no-repeat;
    padding-left: 40px;
} */

.mr-right {
    margin-right: 2rem;
}

.icon {
    width: 40px;
}

.notification-icon {
    width: 23px;
}

._authorbtn {
    background-color: #31197c;
    width: 4.5rem;
    height: 25px;
    border-color: white;
    border-radius: 4px;
    text-align: center;
    margin: auto;
    padding-top: 3px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

._authorbtn:hover {
    background-color: #31197c;
    border-color: white;
    color: white;
}
.search-padding{
    padding:10px 1px;
}
.border-underline{
    border-bottom:1px solid #AAAAAA;
    width:100%;
    margin-top: 11px;
    margin-bottom: 11px;
}
.border-underline-n{
    border-bottom:1px solid #AAAAAA;
    width:100%;
    margin-top: 5px;
    margin-bottom: 11px;
}
.search-p-img{
    width:38px;
    margin-bottom: 11px;
}
.search-p-img_{
    width:38px;
    margin-bottom: 11px;
}
.search-t-gap_{
    margin-top: 2px;
    }
.search-t-gap{
margin-top: 4px;
}
.notification-p-img_{
    width:38px;
    margin-top:6px;
}
.NotificationName{
    font-size: 12px;
    font-family: Poppins;
}
