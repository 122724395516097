.mapItem path {
    outline: none !important;
}

.productImageUpload div {
    display: none;
}
.productImageUpload:hover div {
    display: flex;
}


.scroll-hidden::-webkit-scrollbar {
    display: none
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.content-start {
    align-items: flex-end;
}

.align-items-right {
    text-align: right;
}

.commentText img {
    width: 300px !important;
    height: 220px !important;
    object-fit: contain !important;
}

.error {
    color: red;
    font-size: 10px;
}

.text-orange {
    color: #E6570E;
}

input {
    accent-color: #E6570E !important;
}

.bg-orange {
    background: #E6570E;
}

.vote-bg {
    background: rgba(255, 235, 208, 1)
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.commentText a {
    color: #1D85E8;
    cursor: pointer;
}

.commentText a:hover {
    color: #074a8a;
}

.ImageSpan__root___RoAqL {
    width: 200px !important;
    height: 120px !important;
    background-size: contain !important;
    vertical-align: unset !important;
    line-height: 1 !important;
    font-size: 16px !important;
    letter-spacing: 2px !important;
}

.btn-2:hover {
    color: #FFFFFF;
}

.btn-2:disabled:hover {
    color: currentColor;
}

.btn-2:disabled {
    font-weight: 400;
    opacity: 1;
    color: #666
}

.btn-2:disabled:hover {
    color: #666
}


.opacTranslate {
    animation: animate 500ms ease-in-out;
}

@keyframes animate {
    0% {
        opacity: 0.5;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-dialog.modal-xl.modal-dialog-centered {
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageUploadBox label {
    width: 100%;
}

.opacAnimation {
    animation: animate2 500ms ease-in-out;
}

@keyframes animate2 {
    0% {
        opacity: 0.5;
        transform: translateY(-5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}