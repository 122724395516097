/* .NavBarItems{
    background: linear-gradient(90deg, rgb(110,94,254) 0%,rgba(73,63,25)100%);
    display:flex;
    height: 5rem;
    justify-content: center;
    align-items: center;
    font-size:1.2rem;
    margin-top: 5rem;
} */
.nav-logo {
    margin-left: 2.5rem;
    cursor: pointer;
    width: 3.5%;
    margin-bottom: -3.3rem;
    margin-top: 0rem;
}

.navbar-height {
    height: 4rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    padding: 10px;
    list-style: none;
    text-align: center;
    text-decoration: none;
    width: 100vw;
    justify-content: right;
    margin-top: -0.5rem;
    font-size: small;
    font-family: poppins;
}

.nav-links {
    color: black;
    transition: all 0.2s ease-out;
    padding: 1.2rem;
}

.nav-links:hover {
    color: #E6570E;
}

.nav-marginright {
    margin-right: 60vw;
}

.btn-feddup {
    background-color: #E6570E;
    border-color: 5px solid black;
    font-size: small;
}

.nav-links-feddupbtn {
    background-color: #E6570E;
    width: 9rem;
    height: 30px;
    border-color: 10px solid black;
    border-radius: 30px;
    text-align: center;
    margin-right: 2rem;
    color: white;
    padding-top: 4px;
}

.nav-links-feddupbtn:hover {
    background-color: #E6570E;
    border-color: 10px solid black;
    color: white;
}

.nav-links:hover {
    transition: all 0.2s ease-out;
    text-decoration: none;
}

.fa-bars {
    color: black;
    position: relative;
    top: -1rem;
    right: -0.6rem;
    z-index: 1;
}

.menu-icon {
    display: none;
}

@media screen and (max-width:960px) {
    .NavBarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background-color: rgb(204, 200, 200);
        left: 0px;
        top: 5rem;
        height: auto;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 0.5rem;
        width: 100%;
        display: table;
    }

    .nav-logo {
        position: absolute;
        left: -2.4rem;
        margin-top: -2.6rem;
        transform: translate(25%, 50%);
        width: 12%;
    }

    .navbar-height {
        height: 4.5rem;
        box-shadow: 0px 1px 10px #999;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        font-size: 2rem;
        color: black;
        position: relative;
        top: -0.8rem;
        right: -0.6rem;
        z-index: 1;
    }

    .nav-links-feddupbtn {
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        width: 9rem;
        height: auto;
        background: #E6570E;
        text-decoration: none;
        color: white;
        border-color: #E6570E;
        padding-top: 5px;
        padding-bottom: 8px;
        margin-bottom: 5px;
    }
}